import { StaticImage } from "gatsby-plugin-image";
import React, { FC } from "react";

const CEOTestimonial: FC = () => (
  <section className="py-12 max-w-none overflow-hidden md:py-20 lg:py-24">
    <div className="relative  mx-auto px-4 sm:px-6 lg:px-8">
      <div className="relative">
        <h2 className="text-3xl sm:text-5xl leading-none font-extrabold text-center text-gray-800 tracking-tight mb-8">
          The alternatives weren&apos;t good enough
        </h2>
        <blockquote className="mt-10">
          <div className="max-w-3xl mx-auto text-center text-2xl leading-9 font-medium text-gray-600">
            <p>
              &ldquo;Getting into investing is hard, and when I started, all the
              options were missing key features. I built Vessel to help people
              just like me&rdquo;
            </p>
          </div>
          <footer className="mt-8">
            <div className="md:flex md:items-center md:justify-center">
              <div className="md:flex-shrink-0">
                <StaticImage
                  layout="fixed"
                  className="mx-auto block h-12 w-12 rounded-full"
                  src="./face-cropped.jpg"
                  alt="Alfie Jones"
                  height={48}
                  width={48}
                  quality={100}
                />
              </div>
              <div className="mt-3 text-center md:mt-0 md:ml-4 md:flex md:items-center">
                <div className="text-base font-medium text-gray-900">
                  Alfie Jones
                </div>

                <svg
                  className="hidden md:block mx-1 h-5 w-5 text-blue-600"
                  fill="currentColor"
                  viewBox="0 0 20 20"
                >
                  <path d="M11 0h3L9 20H6l5-20z" />
                </svg>

                <div className="text-base font-medium text-gray-500">
                  CEO & founder, Vessel
                </div>
              </div>
            </div>
          </footer>
        </blockquote>
      </div>
    </div>
  </section>
);

export default CEOTestimonial;
