import React from "react";
import {
  GlobeAltIcon,
  LightningBoltIcon,
  MailIcon,
  ScaleIcon,
} from "@heroicons/react/outline";
import CEOTestimonial from "../components/index/testimonials";
import WhatMakesUsDifferent from "../components/index/whatMakesUsDifferent";
//  import RecentBlogs from "../components/index/recentBlogs";
import Layout from "../components/layout";
import { D2 } from "../components/typography";

const features = [
  {
    name: "Invest Smarter with our Plans",
    description:
      "Choose from a range of our investment plans built by experts. Our plans let you build wealth whilst giving you the peace of mind that your future is planned for. These are perfect for you if you're wanting a zero hassle approach to investing.",
    icon: GlobeAltIcon,
  },
  {
    name: "Invest more with our insights",
    description:
      "Get insights into your spending by linking your bank accounts. We give you tailored tips and suggestions to make your money go further. We can even give you weekly suggestions as to how much you should be investing depending on your financial situation.",
    icon: ScaleIcon,
  },
  {
    name: "Bank level security",
    description:
      "We use top level security comprising of 256bit encryption, 2 step authentication and more. Ensuring your data is safe is our top priority.",
    icon: LightningBoltIcon,
  },
  {
    name: "Grow your knowledge",
    description:
      "We want to grow your money and your knowledge. When you invest in us, we also invest in you giving you free to access money tips, market evaluations and much more.",
    icon: MailIcon,
  },
];

export default function Example() {
  return (
    <Layout
      title="Simply Invest and Save more with our App"
      description="With Vessel you can easily create and manage your investment portfolio. Beginner or expert, you'll find what you need at Vessel"
      canonical=""
      siteName="Vessel"
    >
      <div
        className="hidden sm:block sm:absolute sm:inset-y-0 sm:w-full h-auto"
        aria-hidden="true"
      >
        <div className="relative max-w-7xl mx-auto">
          <svg
            className="absolute right-full transform translate-y-1/4 translate-x-1/4 lg:translate-x-1/2"
            width={404}
            height={784}
            fill="none"
            viewBox="0 0 404 784"
          >
            <defs>
              <pattern
                id="f210dbf6-a58d-4871-961e-36d5016a0f49"
                x={0}
                y={0}
                width={20}
                height={20}
                patternUnits="userSpaceOnUse"
              >
                <rect
                  x={0}
                  y={0}
                  width={4}
                  height={4}
                  className="text-gray-200"
                  fill="currentColor"
                />
              </pattern>
            </defs>
            <rect
              width={404}
              height={500}
              fill="url(#f210dbf6-a58d-4871-961e-36d5016a0f49)"
            />
          </svg>
          <svg
            className="absolute left-full transform -translate-y-3/4 -translate-x-1/4 md:-translate-y-1/2 lg:-translate-x-1/2"
            width={404}
            height={784}
            fill="none"
            viewBox="0 0 404 784"
          >
            <defs>
              <pattern
                id="5d0dd344-b041-4d26-bec4-8d33ea57ec9b"
                x={0}
                y={0}
                width={20}
                height={20}
                patternUnits="userSpaceOnUse"
              >
                <rect
                  x={0}
                  y={0}
                  width={4}
                  height={4}
                  className="text-gray-200"
                  fill="currentColor"
                />
              </pattern>
            </defs>
            <rect
              width={404}
              height={784}
              fill="url(#5d0dd344-b041-4d26-bec4-8d33ea57ec9b)"
            />
          </svg>
        </div>
      </div>

      <header className="mx-auto px-4 mt-10 md:mt-24 relative">
        <div className="text-center">
          <D2 className="tracking-tight max-w-md md:max-w-none mx-auto text-6xl">
            <span className="block">Making your money</span>
            <span className="block bg-gradient-to-r text-transparent bg-clip-text from-blue-600 to-green-500 pb-4">work for you</span>
          </D2>
          <p className="mt-16 md:mt-24 max-w-md mx-auto text-base text-gray-500 sm:text-lg md:text-xl md:max-w-3xl">
            Investing should&apos;t be hard. Here at Vessel, we believe everyone
            should be able invest with zero hassle. From beginners to experts,
            our platform enables you to get the most from your finances.
          </p>
          <div className="mt-5 md:mt-12 max-w-xs mx-auto sm:flex sm:justify-center">
            <div className="rounded-md shadow">
              <a
                href="#"
                className="w-full flex items-center justify-center px-8 py-3 border border-transparent text-base font-medium rounded-md text-white bg-blue-500 hover:bg-blue-600 md:text-lg"
              >
                Get the App
              </a>
            </div>
          </div>
        </div>
      </header>
      <section className="relative max-w-7xl mx-auto pb-12 pt-12 md:pt-64">
        <div className="relative lg:grid lg:grid-cols-3 lg:gap-x-8  py-12 px-4 sm:px-6 lg:px-8  rounded-lg bg-white">
          <div className="lg:col-span-1">
            <h2 className="text-3xl font-extrabold tracking-tight text-gray-900 sm:text-4xl">
              A better way <span className="text-blue-600">save</span> and{" "}
              <span className="text-blue-600">invest</span>
            </h2>
          </div>
          <dl className="mt-10 space-y-10 sm:space-y-0 sm:grid sm:grid-cols-2 sm:gap-x-8 sm:gap-y-10 lg:mt-0 lg:col-span-2">
            {features.map((feature) => (
              <div key={feature.name}>
                <dt>
                  <div className="absolute flex items-center justify-center h-12 w-12 rounded-md bg-blue-500 text-white">
                    <feature.icon className="h-6 w-6" aria-hidden="true" />
                  </div>
                  <p className="mt-5 ml-16 text-lg leading-6 font-medium text-gray-900">
                    {feature.name}
                  </p>
                </dt>
                <dd className="mt-2 ml-16 text-base text-gray-500">
                  {feature.description}
                </dd>
              </div>
            ))}
          </dl>
        </div>
      </section>
      <CEOTestimonial />
      <WhatMakesUsDifferent />
    </Layout>
  );
}
