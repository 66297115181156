import { StaticImage } from "gatsby-plugin-image";
import React, { FC } from "react";

const WhatMakesUsDifferent: FC = () => (
  <div className="py-16 overflow-hidden relative">
    <div className="max-w-7xl mx-auto px-4 space-y-8 sm:px-6 lg:px-8">
      <div className="text-base max-w-prose mx-auto lg:max-w-none">
        <h2 className="text-base text-blue-600 font-semibold tracking-wide uppercase">
          Holistic Investing
        </h2>
        <p className="mt-2 text-3xl leading-8 font-extrabold tracking-tight text-gray-900 sm:text-4xl">
          What makes us different
        </p>
      </div>
      <div className="relative z-10 text-base max-w-prose mx-auto lg:max-w-5xl lg:mx-0 lg:pr-72">
        <p className="text-lg text-gray-500">
          We recognise that there are many factors in your personal finances. We
          aim to take a holistic approach and ensure we help your in all areas.
          Be it saving money on bills, cutting out spending or simply investing
          in our plans; we&apos;ve got you covered.
        </p>
      </div>
      <div className="lg:grid lg:grid-cols-2 lg:gap-8 lg:items-start">
        <div className="relative z-10 ">
          <div className="prose prose-blue text-gray-500 max-w-prose mx-auto lg:max-w-none">
            <h3 className="text-lg font-bold">
              Vessel was founded on the idea of being better
            </h3>
            <ul className="pt-3">
              <li>
                <span className="text-blue-600">Better</span> for our customers
              </li>
              <li>
                <span className="text-blue-600">Better</span> than our
                competitors
              </li>
              <li>
                <span className="text-blue-600">Better</span> for the community
              </li>
            </ul>
            <p className="pt-5">
              We&apos;re always improving our offerings by listening to our
              community.
            </p>
            <h3 className="pt-5 text-lg font-bold">We’re here to help</h3>
            <p className="pt-3">
              Please reach out to us if you have any questions, need help, or
              just want to say hi 👋.
            </p>
          </div>
          <div className="mt-10 flex text-base max-w-prose mx-auto lg:max-w-none">
            <div className="rounded-md shadow">
              <a
                href="#"
                className="w-full flex items-center justify-center px-5 py-3 border border-transparent text-base font-medium rounded-md text-white bg-blue-600 hover:bg-blue-700"
              >
                Contact us
              </a>
            </div>
            <div className="rounded-md shadow ml-4">
              <a
                href="#"
                className="w-full flex items-center justify-center px-5 py-3 border border-transparent text-base font-medium rounded-md text-blue-600 bg-white hover:bg-gray-50"
              >
                Our features
              </a>
            </div>
          </div>
        </div>
        <div className="mt-12 relative text-base max-w-prose mx-auto lg:mt-0 lg:max-w-none">
          <svg
            className="absolute top-0 right-0 -mt-20 -mr-20 lg:top-auto lg:right-auto lg:bottom-1/2 lg:left-1/2 lg:mt-0 lg:mr-0 xl:top-0 xl:right-0 xl:-mt-20 xl:-mr-20"
            width={404}
            height={384}
            fill="none"
            viewBox="0 0 404 384"
            aria-hidden="true"
          >
            <defs>
              <pattern
                id="bedc54bc-7371-44a2-a2bc-dc68d819ae60"
                x={0}
                y={0}
                width={20}
                height={20}
                patternUnits="userSpaceOnUse"
              >
                <rect
                  x={0}
                  y={0}
                  width={4}
                  height={4}
                  className="text-gray-200"
                  fill="currentColor"
                />
              </pattern>
            </defs>
            <rect
              width={404}
              height={384}
              fill="url(#bedc54bc-7371-44a2-a2bc-dc68d819ae60)"
            />
          </svg>
          <blockquote className="relative bg-white rounded-lg shadow-lg">
            <div className="rounded-t-lg px-6 py-8 sm:px-10 sm:pt-10 sm:pb-8">
              <div className="relative text-lg text-gray-700 font-medium mt-8">
                <svg
                  className="absolute top-0 left-0 transform -translate-x-3 -translate-y-2 h-8 w-8 text-gray-200"
                  fill="currentColor"
                  viewBox="0 0 32 32"
                  aria-hidden="true"
                >
                  <path d="M9.352 4C4.456 7.456 1 13.12 1 19.36c0 5.088 3.072 8.064 6.624 8.064 3.36 0 5.856-2.688 5.856-5.856 0-3.168-2.208-5.472-5.088-5.472-.576 0-1.344.096-1.536.192.48-3.264 3.552-7.104 6.624-9.024L9.352 4zm16.512 0c-4.8 3.456-8.256 9.12-8.256 15.36 0 5.088 3.072 8.064 6.624 8.064 3.264 0 5.856-2.688 5.856-5.856 0-3.168-2.304-5.472-5.184-5.472-.576 0-1.248.096-1.44.192.48-3.264 3.456-7.104 6.528-9.024L25.864 4z" />
                </svg>
                <p className="relative">
                  Here at Vessel, our top priority are ensuring our customers
                  are getting the best possible experience. We&apos;re always
                  listening to your feedback improving our services.
                </p>
              </div>
            </div>
            <cite className="relative flex items-center sm:items-start bg-blue-600 rounded-b-lg not-italic py-5 px-6 sm:py-5 sm:pl-12 sm:pr-10 sm:mt-10">
              <div className="relative rounded-full border-2 border-white sm:absolute sm:top-0 sm:transform sm:-translate-y-1/2">
                <StaticImage
                  layout="fixed"
                  className="mx-auto block h-12 w-12 rounded-full sm:w-20 sm:h-20"
                  src="./face-cropped.jpg"
                  alt="Alfie Jones"
                  height={60}
                  width={60}
                  quality={100}
                />
              </div>
              <span className="relative ml-4 text-blue-200 font-semibold leading-6 sm:ml-24 sm:pl-1">
                <p className="text-white font-semibold sm:inline">
                  Alfie Jones
                </p>{" "}
                <p className="sm:inline">CEO & founder, Vessel</p>
              </span>
            </cite>
          </blockquote>
        </div>
      </div>
    </div>
  </div>
);

export default WhatMakesUsDifferent;
